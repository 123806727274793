import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useLocaleStore } from '@/store/locale'
import { useLoginStore } from '@/store/login'
import { treeForeach, i18nTJs, TDK } from '@/utils/tools/Base'

/**
 * 递归处理菜单数据
 * @param {*} data
 * @returns
 */
const diGui = data => {
    let modules = import.meta.glob('./views/**/*.vue')
    data.map(item => {
        if (item.url === 'Layout') {
            item.component = modules[/* webpackChunkName: '[request]' */ './components/Layout/index.vue']
        } else {
            item.component = modules[/* webpackChunkName: '[request]' */ `./views/${item.url}/index.vue`]
        }
        if (item.children && item.children.length !== 0) {
            diGui(item.children)
        }
    })

    return data
}

/**
 * 动态添加路由
 */
export const addRouter = menuList => {
    const data = diGui(menuList)
    treeForeach(data, node => {
        router.addRoute('Home', node)
    })

    // 最后添加404页面
    router.addRoute('Home', {
        path: '/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: '404' */ './views/404/index.vue')
    })
}

let routeFlag = false
router.beforeEach(async (to, from, next) => {
    NProgress.start()

    // 解决切换路由滚动条不及时恢复到顶部
    const element = document.getElementById('content-body')
    if (element) {
        element.scrollTop = 0
    }

    // eslint-disable-next-line no-unused-vars
    const localStore = useLocaleStore()
    const loginStore = useLoginStore()

    // 在js中可以这么使用
    // console.log(i18n.global.t('login.password'))
    if (loginStore.userInfo.token) {
        if (routeFlag) {
            next()
            // console.log('permission.国际化. ', i18nTJs('login.password'))
            TDK(to.meta.title)
        } else {
            routeFlag = true
            addRouter(loginStore.menuList)
            next({ ...to, replace: false })
        }
    } else {
        routeFlag = false
        if (to.name === 'Login' || to.name === 'Register') {
            next()
        } else {
            next({
                name: 'Login',
                query: { redirect: to.fullPath }
            })
        }
        // console.log('permission.国际化. ', i18nTJs('login.password'))
        TDK(to.meta.title)
    }
})

router.afterEach(() => {
    NProgress.done()
})
