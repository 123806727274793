import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.variable.min.css'
import mitt from 'mitt'
import 'normalize.css/normalize.css'
import { createApp } from 'vue'
import App from './App.vue'
// import './assets/css/base.less'
import routers from './router'
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import { i18n } from './locale'
import { loading } from './components/Loading'
import './permission'
// import print from 'vue3-print-nb'
// 自定义指令
import { directive } from './utils/directive'

const app = createApp(App)
const pinia = createPinia()
// pinia 持久化插件
pinia.use(piniaPersist)

app.use(Antd)
app.use(routers)
app.use(pinia)
app.use(i18n)
app.use(loading)

// app.use(print)

directive(app)

// vue3中第三方插件  类似于事件总线 -- mitt
app.config.globalProperties.$mitt = mitt()
// 用于调整是否移除模板中的 HTML 注释。
app.config.compilerOptions.comments = true
app.mount('#app')
