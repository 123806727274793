import { defineStore } from 'pinia'

export const useLocaleStore = defineStore('locale', {
    state: () => ({
        language: 'zh'
    }),
    getters: {},
    actions: {
        changeLanguage(language) {
            this.language = language
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'locale-store',
                storage: sessionStorage
            }
        ]
    }
})
