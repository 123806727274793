import config from '../config'
import { i18n } from '@/locale'
import { message } from 'ant-design-vue'
import { getCurrentInstance } from 'vue'
// import { createFromIconfontCN } from '@ant-design/icons-vue'

// 判断当前是什么模式
export const modelServer = () => {
    return import.meta.env.MODE === 'dev' ? '/api' : '/api'
}

// 返回服务地址
export const serverUrl = () => {
    return config[import.meta.env.MODE].serverUrl
}

// 获取全局实例
export const getInstance = () => {
    return getCurrentInstance().appContext.config.globalProperties
}

// 国际化在js中使用
export const i18nTJs = str => {
    if (str) {
        return i18n.global.t(str)
    }
    message.error('翻译字段不能为空!')
}

// 动态引入图片
export const dyImportImage = (path, fileName) => {
    return new URL(`../../assets/${path}/${fileName}`, import.meta.url).href
}

/**
 * 文件流下载
 * @param {文件流} res
 * @param {文件名称} fileName
 */
export const FileExport = (res, fileName) => {
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([res]))
    link.target = '_blank'
    link.setAttribute('download', fileName)
    //文件名和格式
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

/**
 * 设置TDK
 * @param title
 * @param keywords
 * @param description
 * @constructor
 */
export const TDK = (title, keywords = '', description = '') => {
    document.title = title
    let meta = document.getElementsByTagName('meta')
    meta.keywords.setAttribute('content', keywords)
    meta.description.setAttribute('content', description)
}

/**
 * 生成UUID
 * @returns {string}
 * @constructor
 */
export const UUID = () => {
    let s = []
    let hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
    s[8] = s[13] = s[18] = s[23] = '-'

    let uuid = s.join('')
    return uuid
}

/**
 * [deepClone 深度克隆]
 * @param  {[type]} obj [克隆对象]
 * @return {[type]}     [返回深度克隆后的对象]
 */
export const deepClone = obj => {
    if (obj === null || typeof obj !== 'object') {
        return obj
    }
    let isType = function (obj, type) {
        let flag,
            typeString = Object.prototype.toString.call(obj)
        switch (type) {
            case 'Array':
                flag = typeString === '[object Array]'
                break
            case 'Date':
                flag = typeString === '[object Date]'
                break
            case 'RegExp':
                flag = typeString === '[object RegExp]'
                break
            default:
                flag = false
        }
        return flag
    }
    let getRegExp = function (re) {
        let flags = ''
        if (re.global) {
            flags += 'g'
        }
        if (re.ignoreCase) {
            flags += 'i'
        }
        if (re.multiline) {
            flags += 'm'
        }
        return flags
    }

    let _clone = function (parent) {
        let child,
            proto,
            parents = [],
            children = []
        if (isType(parent, 'Array')) {
            // 对数组做特殊处理
            child = []
        } else if (isType(parent, 'RegExp')) {
            // 对正则做特殊处理
            child = new RegExp(parent.source, getRegExp(parent))
            if (parent.lastIndex) {
                child.lastIndex = parent.lastIndex
            }
        } else if (isType(parent, 'Date')) {
            // 对Date做特殊处理
            child = new Date(parent.getTime())
        } else {
            // 处理对象原型
            proto = Object.getPrototypeOf(parent)
            // 利用Object.create切断原型链
            child = Object.create(proto)
        }
        // 处理循环引用
        let index = parents.indexOf(parent)

        if (index !== -1) {
            // 如果父数组存在本对象,说明之前已经被引用过,直接返回此对象
            return children[index]
        }
        parents.push(parent)
        children.push(child)

        for (let i in parent) {
            child[i] = _clone(parent[i])
        }

        return child
    }
    return _clone(obj)
}

/*获取网址参数*/
export const getUrlParams = name => {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    let r = decodeURI(window.location.search).substr(1).match(reg)
    if (r !== null) {
        return r[2]
    }
    return null
}

/*获取全部url参数,并转换成json对象*/
export const getUrlAllParams = url => {
    url = url ? url : window.location.href
    let _pa = url.substring(url.indexOf('?') + 1),
        _arrS = _pa.split('&'),
        _rs = {}
    for (let i = 0, _len = _arrS.length; i < _len; i++) {
        let pos = _arrS[i].indexOf('=')
        if (pos === -1) {
            continue
        }
        let name = _arrS[i].substring(0, pos),
            value = window.decodeURIComponent(_arrS[i].substring(pos + 1))
        _rs[name] = value
    }
    return _rs
}

/*删除url指定参数，返回url*/
export const delParamsUrl = (url, name) => {
    let baseUrl = url.split('?')[0] + '?'
    let query = url.split('?')[1]
    if (query.indexOf(name) > -1) {
        let obj = {}
        let arr = query.split('&')
        for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].split('=')
            obj[arr[i][0]] = arr[i][1]
        }
        delete obj[name]
        let url =
            baseUrl +
            JSON.stringify(obj)
                .replace(/[\\"\\{\\}]/g, '')
                .replace(/\\:/g, '=')
                .replace(/\\,/g, '&')
        return url
    }
    return url
}

/*获取十六进制随机颜色*/
export const getRandomColor = () => {
    return (
        '#' +
        (function (h) {
            return new Array(7 - h.length).join('0') + h
        })(((Math.random() * 0x1000000) << 0).toString(16))
    )
}

/*图片加载*/
export const imgLoadAll = (arr, callback) => {
    let arrImg = []
    for (let i = 0; i < arr.length; i++) {
        let img = new Image()
        img.src = arr[i]
        img.onload = function () {
            arrImg.push(this)
            if (arrImg.length === arr.length) {
                callback && callback()
            }
        }
    }
}

/*音频加载*/
export const loadAudio = (src, callback) => {
    let audio = new Audio(src)
    audio.onloadedmetadata = callback
    audio.src = src
}

/*DOM转字符串*/
export const domToStirng = htmlDOM => {
    let div = document.createElement('div')
    div.appendChild(htmlDOM)
    return div.innerHTML
}
/*字符串转DOM*/
export const stringToDom = htmlString => {
    let div = document.createElement('div')
    div.innerHTML = htmlString
    return div.children[0]
}

/**
 * 树形结构数据遍历
 * @param {*} tree
 * @param {*} func
 */
export const treeForeach = (tree, func) => {
    let node,
        list = [...tree]
    while ((node = list.shift())) {
        func(node)
        node.children && list.push(...node.children)
    }
}

/**
 * 防抖
 * @param fn 方法
 * @param delay 时间
 * @returns {(function(...[*]): void)|*}
 *
 * demo ===>  debounce(fn, 1000)
 */
export const debounce = (fn, delay) => {
    let timer

    return function (...args) {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn.apply(this, args)
        }, delay)
    }
}

/**
 * 节流
 * @param fn  方法
 * @param delay  时间
 * @returns {(function(...[*]): void)|*}
 *
 * demo ===> throttle(fn,1000)
 */
export const throttle = (fn, delay) => {
    let last = 0 // 上次触发时间

    return (...args) => {
        const now = Date.now()

        if (now - last > delay) {
            last = now
            fn.apply(this, args)
        }
    }
}

/**
 * 判断是否为URL
 * @param path
 * @returns {boolean}
 */
export const isUrl = path => {
    const reg =
        /(((^https?:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%\/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)$/ // eslint-disable-line
    return reg.test(path)
}

/**
 * 延迟执行
 * @param delay 毫秒
 */
export const sleep = delay => new Promise(resolve => setTimeout(resolve, delay))

/**
 * 获取滚动条宽度
 * @returns {number}
 */
export const getScrollbarWidth = () => {
    // 创建外层div，让它始终显示滚动条
    const outer = document.createElement('div')
    outer.style.visibility = 'hidden'
    // 强制显示滚动条
    outer.style.overflow = 'scroll'
    // needed for WinJS apps
    outer.style.msOverflowStyle = 'scrollbar'
    document.body.appendChild(outer)
    // 创建内层div
    const inner = document.createElement('div')
    outer.appendChild(inner)
    // 外层div减去内层div，得到的就是滚动条的宽度（横向就是高度）
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth
    // 移除div
    outer.parentNode.removeChild(outer)
    return scrollbarWidth
}
