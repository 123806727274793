export const directive = app => {
    // 防抖
    // v-debounce="{fn: handleClick, event: 'click', delay: 200}"
    app.directive('debounce', {
        mounted(el, binding) {
            // 至少需要回调函数以及监听事件类型
            if (typeof binding.value.fn !== 'function' || !binding.value.event) {
                return
            }
            let delay = 200 // 默认延迟时间
            el.timer = null
            el.handler = function () {
                if (el.timer) {
                    clearTimeout(el.timer)
                    el.timer = null
                }
                el.timer = setTimeout(() => {
                    binding.value.fn.apply(this, arguments)
                    el.timer = null
                }, binding.value.delay || delay)
            }
            el.addEventListener(binding.value.event, el.handler)
        },
        // 元素卸载前也记得清理定时器并且移除监听事件
        beforeMount(el, binding) {
            if (el.timer) {
                clearTimeout(el.timer)
                el.timer = null
            }
            el.removeEventListener(binding.value.event, el.handler)
        }
    })

    // 节流
    // v-throttle="{fn: handleInput, event: 'input', delay: 1000}"
    app.directive('throttle', {
        mounted(el, binding) {
            // 至少需要回调函数以及监听事件类型
            if (typeof binding.value.fn !== 'function' || !binding.value.event) {
                return
            }
            let delay = 200
            el.timer = null
            el.handler = function () {
                if (el.timer) {
                    return
                }
                el.timer = setTimeout(() => {
                    binding.value.fn.apply(this, arguments)
                    el.timer = null
                }, binding.value.delay || delay)
            }
            el.addEventListener(binding.value.event, el.handler)
        },
        // 元素卸载前也记得清理定时器并且移除监听事件
        beforeMount(el, binding) {
            if (el.timer) {
                clearTimeout(el.timer)
                el.timer = null
            }
            el.removeEventListener(binding.value.event, el.handler)
        }
    })

    // 水印
    // v-watermark="{ text: '水印名称', textColor: 'rgba(180, 180, 180, 1)' }"
    app.directive('watermark', (el, binding) => {
        function addWaterMarker(str, parentNode, font, textColor) {
            // 水印文字，父元素，字体，文字颜色
            let can = document.createElement('canvas')
            parentNode.appendChild(can)
            can.width = 300
            can.height = 200
            can.style.display = 'none'
            let cans = can.getContext('2d')
            cans.rotate((-15 * Math.PI) / 180)
            cans.font = font || '16px Microsoft JhengHei'
            cans.fillStyle = textColor || 'rgba(180, 180, 180, 0.3)'
            cans.textAlign = 'left'
            cans.textBaseline = 'Middle'
            cans.fillText(str, can.width / 3, can.height / 2)
            parentNode.style.backgroundImage = 'url(' + can.toDataURL('image/png') + ')'
        }
        addWaterMarker(binding.value.text, el, binding.value.font, binding.value.textColor)
    })
}
