import { defineStore } from 'pinia'
import { ConfigProvider } from 'ant-design-vue'

export const useThemeStore = defineStore('theme', {
    state: () => ({
        color: '#5046bb'
    }),
    getters: {},
    actions: {
        changeTheme(color) {
            ConfigProvider.config({
                theme: {
                    primaryColor: color
                }
            })
            this.color = color
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'locale-theme',
                storage: sessionStorage
            }
        ]
    }
})
