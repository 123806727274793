<template>
    <a-config-provider :locale="locale === 'en' ? enUS : zhCN">
        <router-view />
    </a-config-provider>
</template>
<script>
import { defineComponent, onBeforeMount, onMounted, getCurrentInstance, nextTick } from 'vue'
import { useLocaleStore } from '@/store/locale'
import { useThemeStore } from '@/store/theme'
import enUS from 'ant-design-vue/es/locale/en_US'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import 'dayjs/locale/zh-cn.js'
import { ConfigProvider } from 'ant-design-vue'

export default defineComponent({
    // setup执行顺序在onMounted之前 （是属于beforeCreate，created）
    setup() {
        const localeStore = useLocaleStore()
        const themeStore = useThemeStore()
        const { proxy } = getCurrentInstance()
        proxy.$loading.show()

        onBeforeMount(() => {
            // 监听浏览器是否断网
            nextTick(() => {
                window.addEventListener('online', () => {
                    proxy.$loading.hide()
                })
                window.addEventListener('offline', function () {
                    proxy.$loading.show('网络好像不太好哦～')
                })
            })

            // 设置主题色
            ConfigProvider.config({
                theme: {
                    primaryColor: themeStore.color
                }
            })

            // 设置语言
            proxy.$i18n.locale = localeStore.language
        })

        onMounted(() => {
            // setTimeout(() => {
            proxy.$loading.hide()
            // }, 2000)
        })
    },
    data() {
        return {
            locale: 'zh',
            enUS,
            zhCN
        }
    },

    watch: {
        '$i18n.locale': {
            //观察用户语言变量，更新第三方语言
            handler(language) {
                this.locale = language
            },
            immediate: true
        }
    }
})
</script>
<style lang="less">
@import './assets/css/base.less';
</style>
<style lang="less" scoped></style>
