export const simpleTableMock = [
    {
        url: '/api/getTableList',
        method: 'get',
        response: () => {
            return {
                code: 200,
                message: 'ok',
                success: true,
                data: [
                    { id: 1, name: '张三', age: 21, date: '2022-08-09' },
                    { id: 2, name: '张三', age: 22, date: '2022-08-09' },
                    { id: 3, name: '张三', age: 23, date: '2022-08-09' },
                    { id: 4, name: '张三', age: 24, date: '2022-08-09' },
                    { id: 5, name: '张三', age: 25, date: '2022-08-09' },
                    { id: 6, name: '张三', age: 26, date: '2022-08-09' }
                ]
            }
        }
    }
]
