export const loginMock = [
    {
        url: '/api/Login',
        method: 'post',
        response: () => {
            return {
                code: 200,
                message: 'ok',
                success: true,
                data: {
                    id: 'bb121c78-0af9-7f7b-01ff-9fc7aa134cbb',
                    name: '赖东东',
                    age: 99,
                    address: '江苏省徐州市云龙区',
                    lover: '97,hr,开发小妹',
                    token: 'eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTA0MTk2ODAsIlVfSUQiOiIxNDM5NTAyNDAxMTkxNTUwOTc4IiwiSVNfQURNSU4iOnRydWUsIk9SR19JRCI6IjE0MzgzNzI4MjUwNDgxNTAwMTciLCJDUkVBVEVfVElNRSI6MTY1MDQxOTY4MDk3N30.3a8AD_IE4G-7SjsLDDBPOGtcR7ETlw3ilqsUfDQPm-Q'
                }
            }
        }
    },
    {
        url: '/api/MenuList',
        method: 'get',
        response: () => {
            return {
                code: 200,
                message: 'ok',
                success: true,
                data: [
                    {
                        path: '',
                        name: '',
                        url: 'Layout',
                        icon: 'icon-zujian',
                        meta: { title: '基础组件', i18n: 'menu.basicComponents' },
                        children: [
                            {
                                path: '/buttom',
                                name: 'buttom',
                                url: 'Basics/Buttom',
                                icon: 'icon-anniu',
                                meta: { title: '按钮', i18n: 'menu.buttonText' }
                            },
                            {
                                path: '/radio',
                                name: 'radio',
                                url: 'Basics/Radio',
                                icon: 'icon-radiobox',
                                meta: { title: '单选框', i18n: 'menu.radioText' }
                            },
                            {
                                path: '/input',
                                name: 'input',
                                url: 'Basics/Input',
                                icon: 'icon-input',
                                meta: { title: '输入框', i18n: 'menu.inputText' }
                            },
                            {
                                path: '/select',
                                name: 'select',
                                url: 'Basics/Select',
                                icon: 'icon-select',
                                meta: { title: '选择框', i18n: 'menu.selectText' }
                            },
                            {
                                path: '/timePicker',
                                name: 'timePicker',
                                url: 'Basics/TimePicker',
                                icon: 'icon-shijian',
                                meta: { title: '时间', i18n: 'menu.timePickerText' }
                            },
                            {
                                path: '/datePicker',
                                name: 'datePicker',
                                url: 'Basics/DatePicker',
                                icon: 'icon-riqi',
                                meta: { title: '日期', i18n: 'menu.datePickerText' }
                            },
                            {
                                path: '/rate',
                                name: 'rate',
                                url: 'Basics/Rate',
                                icon: 'icon-rate',
                                meta: { title: '评分', i18n: 'menu.rateText' }
                            },
                            {
                                path: '/image',
                                name: 'image',
                                url: 'Basics/Image',
                                icon: 'icon-tupian',
                                meta: { title: '图片', i18n: 'menu.imageText' }
                            },
                            {
                                path: '/table',
                                name: 'table',
                                url: 'Basics/Table',
                                icon: 'icon-table',
                                meta: { title: '表格', i18n: 'menu.tableText' }
                            }
                        ]
                    },
                    {
                        path: '',
                        name: '',
                        url: 'Layout',
                        icon: 'icon-fuzajichengjiagou',
                        meta: { title: '复杂组件', i18n: 'menu.complexComponents' },
                        children: [
                            {
                                path: '/infiniteList',
                                name: 'infiniteList',
                                url: 'Complex/InfiniteList',
                                icon: 'icon-liebiao',
                                meta: { title: '大数据加载', i18n: 'menu.bigData' }
                            },
                            {
                                path: '/flowChart',
                                name: 'flowChart',
                                url: 'Complex/FlowChart',
                                icon: 'icon-liebiao',
                                meta: { title: '流程图', i18n: 'menu.flowChart' }
                            }
                        ]
                    },
                    {
                        path: '',
                        name: '',
                        url: 'Layout',
                        icon: 'icon-leaflet',
                        meta: { title: '地图组件', i18n: 'menu.mapComponents' },
                        children: [
                            {
                                path: '/tiandituMap',
                                name: 'tiandituMap',
                                url: 'Map/TianDiTu',
                                icon: 'icon-ditu',
                                meta: { title: '天地图', i18n: 'menu.tiandituMap' }
                            },
                            {
                                path: '/gaodeMap',
                                name: 'gaodeMap',
                                url: 'Map/GaoDe',
                                icon: 'icon-mapOfGaud',
                                meta: { title: '高德地图', i18n: 'menu.gaodeMap' }
                            },
                            {
                                path: '/googleMap',
                                name: 'googleMap',
                                url: 'Map/Google',
                                icon: 'icon-guge',
                                meta: { title: '谷歌地图', i18n: 'menu.googleMap' }
                            },
                            {
                                path: '/tencentMap',
                                name: 'tencentMap',
                                url: 'Map/Tencent',
                                icon: 'icon-tengxunditu',
                                meta: { title: '腾讯地图', i18n: 'menu.tencentMap' }
                            }
                        ]
                    },
                    {
                        path: '',
                        name: '',
                        url: 'Layout',
                        icon: 'icon-gongjuxiang',
                        meta: { title: '工具类', i18n: 'menu.toolsText' },
                        children: [
                            {
                                path: '/editor',
                                name: 'editor',
                                url: 'Tool/Editor',
                                icon: 'icon-bianjiqi',
                                meta: { title: '编辑器', i18n: 'menu.editText' }
                            },
                            {
                                path: '/markdown',
                                name: 'markdown',
                                url: 'Tool/MarkDown',
                                icon: 'icon-markdown',
                                meta: { title: 'MarkDown', i18n: 'menu.markDown' }
                            },
                            {
                                path: '/leader-line',
                                name: 'leader-line',
                                url: 'Tool/LeaderLine',
                                icon: 'icon-curve',
                                meta: { title: '不规则曲线', i18n: 'menu.leaderLine' }
                            },
                            {
                                path: '/watermark',
                                name: 'watermark',
                                url: 'Tool/Watermark',
                                icon: 'icon-shuiyin',
                                meta: { title: '自定义水印', i18n: 'menu.watermark' }
                            },
                            {
                                path: '/qrcode',
                                name: 'qrcode',
                                url: 'Tool/Qrcode',
                                icon: 'icon-erweima',
                                meta: { title: '二维码', i18n: 'menu.qrcode' }
                            },
                            {
                                path: '/video',
                                name: 'video',
                                url: 'Tool/Video',
                                icon: 'icon-shipin',
                                meta: { title: '视频播放器', i18n: 'menu.video' }
                            },
                            {
                                path: '/pdf',
                                name: 'pdf',
                                url: 'Tool/PDF',
                                icon: 'icon-pdf',
                                meta: { title: 'PDF导出', i18n: 'menu.pdf' }
                            }
                        ]
                    },
                    {
                        path: '',
                        name: '',
                        url: 'Layout',
                        icon: 'icon-vue',
                        meta: { title: 'Vue3 API', i18n: 'menu.apiText' },
                        children: [
                            {
                                path: '/v-memo',
                                name: 'v-memo',
                                url: 'Vue3Api/Vmemo',
                                icon: 'icon-vue-api',
                                meta: { title: 'Vmemo', i18n: 'menu.vmemoText' }
                            }
                        ]
                    },
                    {
                        path: 'https://www.baidu.com',
                        name: 'Layout',
                        url: 'Layout',
                        icon: 'icon-BaiDu',
                        meta: { title: '百度一下', i18n: 'menu.baiduText' }
                    },
                    {
                        path: 'https://www.jd.com',
                        name: 'Layout',
                        url: 'Layout',
                        icon: 'icon-jingdong',
                        meta: { title: '京东商城', i18n: 'menu.jdText' }
                    }
                ]
            }
        }
    }
]
