import { defineStore } from 'pinia'
import { LoginServer, MenuListServer } from '@/api/login'
import router from '@/router'
import { addRouter } from '@/permission'
import { isUrl } from '@/utils/tools/Base'
import { userHomeStore } from './home.js'

export const useLoginStore = defineStore('login', {
    state: () => ({
        userInfo: {},
        menuList: []
    }),
    getters: {},
    actions: {
        async loginBtn(param) {
            const response = await LoginServer(param)
            if (response?.success) {
                this.userInfo = response?.data
                await this.getMenuList()
            }
        },
        async getMenuList() {
            const response = await MenuListServer()
            if (response?.success) {
                this.menuList = response?.data

                // 在集合中寻找第一节点的path不为url的加载
                const list = this.menuList.find(e => {
                    if (!isUrl(e.path)) {
                        return e
                    }
                })

                this.openMenuChild(list)
            }
        },

        // 登录后选中第一个节点
        openMenuChild(data) {
            const homeStore = userHomeStore()
            if (data.children && data.children.length !== 0) {
                if (data.url !== 'Layout') {
                    // 添加动态路由
                    addRouter(this.menuList)
                    // 默认选择第一个节点
                    homeStore.updateSelectKeys([`/${data.name}`])
                    router.push({
                        path: data.path
                    })
                } else {
                    homeStore.updateOpenKeys([data.icon])
                    this.openMenuChild(data.children[0])
                }
            } else {
                // 如果该默认集合中有children 第一节点在children中找
                // 如果该默认集合中没有children，第一节点就是自身
                homeStore.updateSelectKeys([`/${data.name}`])
                router.push({
                    path: data.path
                })
            }
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'login-store',
                storage: sessionStorage,
                paths: ['userInfo', 'menuList']
            }
        ]
    }
})
