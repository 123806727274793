export default {
    dev: {
        serverUrl: '/api' // 测试接口域名
    },
    test: {
        serverUrl: '/api' // 测试接口域名
    },
    pred: {
        serverUrl: '/api' // 预生产接口域名
    },
    prod: {
        serverUrl: '/api' // 生产接口域名
    }
}
