import { createI18n } from 'vue-i18n'

const language = ((navigator.language ? navigator.language : navigator.userLanguage) || 'zh').toLowerCase()

let modules = import.meta.glob('./language/*.js')

let messages = {}
for (const key in modules) {
    modules[key]().then(mod => {
        const name = key.replace(/[^\\\/]*[\\\/]+/g, '')
        const postfix = /\.[^\.]+/.exec(name)
        const fileName = name.substr(0, postfix.index)
        messages[fileName] = mod.default
    })
}

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: language.split('-')[0] || 'zh',
    messages
})

export { i18n, language }
