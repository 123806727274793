import { defineStore } from 'pinia'

export const userHomeStore = defineStore('home', {
    state: () => ({
        collapsed: false,
        openKeys: [],
        selectedKeys: []
    }),
    getters: {},
    actions: {
        updateCollapsed(status) {
            this.collapsed = status
        },

        updateOpenKeys(keys) {
            this.openKeys = keys
        },

        updateSelectKeys(keys) {
            this.selectedKeys = keys
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'home-store',
                storage: sessionStorage
            }
        ]
    }
})
